import {generateEnhancedCardPreview} from '@frontend/wallet-cards';
import {
    RECEIVE_DECISION_CENTER_BASIC_DATA,
    SET_CUSTOMER_RELATED_PROFILES,
    SET_CUSTOMER_ACCESS_TOKEN,
    SET_CUSTOMER_NETWORK_NAME,
    SET_JOURNEY_INITIAL_ADDRESS,
    SET_JOURNEY_ADDRESS,
    SET_ACTIVE_DECISION_TAB,
    SET_JOURNEY_LOCALE,
    SET_CARD_TEMPLATES,
    SET_STEERAGE_TYPES,
    SET_CUSTOM_STEERAGE_TYPES,
    SET_SELECTED_CUSTOMER_PROFILE,
    SET_ACTIVE_DECISION_KIND,
    SET_PRODUCT_HOSTNAME,
    SET_SEARCH_CONTEXT_ID,
    SET_PROVIDER_SEARCH_EXTRA_PARAMS,
    SET_WALLET_CARDS,
    SET_KISX_DATA
} from './actionTypes';
import apiServices from '../apiServices';
import {requestProvider, setActiveNpi, setProviderList} from './providers';
import {getDraftData, setFormData} from './shared';
import {
    DECISION_FORMS_BY_TYPE,
    LANGUAGE_LOCALES,
    DECISION_DEFAULT_CARD_TITLES,
    DECISION_TYPES,
    TICKET_SOURCES,
    DECISION_CENTER_CONTEXT_NAME,
    DECISION_KINDS,
    DRAFT_ITEMS
} from '../constants';
import {generateBaseDecision, formatAddress} from '../helpers';
import {
    parseURL,
    stringifyURL,
    isEmpty,
    equal,
    getEqual,
    generateUniqueId,
    groupBy,
    getTextFromHtml
} from '../utils';

const sortOfficeByIntakeOfficeId = intakeOfficeIds => ({id}) => intakeOfficeIds.includes(id) ? -1 : 1;

const getFormattedWalletCards = card => {
    const {category, view} = card;
    const {plan_id: planId} = view;
    const {title} = generateEnhancedCardPreview(card);

    return {
        title: getTextFromHtml(title),
        category,
        planId
    };
};

const getUpdatedDecision = decision => {
    const {details, kind, ...restDecision} = decision;
    const {plan_id: planId, category} = details;
    const isWalletCardKind = equal(kind, DECISION_KINDS.walletCard);

    const updatedDetails = {
        ...details,
        ...isWalletCardKind && {
            walletCardId: planId || category,
            category
        }
    };

    return {...restDecision, kind, details: updatedDetails, cid: generateUniqueId()};
};

const receiveDecisionCenterBasicData = basicData => ({type: RECEIVE_DECISION_CENTER_BASIC_DATA, ...basicData});
const receiveCardTemplates = cardTemplates => ({type: SET_CARD_TEMPLATES, cardTemplates});

export const setCustomerRelatedProfiles = customerRelatedProfiles => ({type: SET_CUSTOMER_RELATED_PROFILES, customerRelatedProfiles});

export const setWalletCards = walletCards => ({type: SET_WALLET_CARDS, walletCards});

export const setSelectedCustomerProfile = selectedCustomerProfile => ({type: SET_SELECTED_CUSTOMER_PROFILE, selectedCustomerProfile});

export const setCustomerAccessToken = customerAccessToken => ({type: SET_CUSTOMER_ACCESS_TOKEN, customerAccessToken});

export const setCustomerNetworkName = customerNetworkName => ({type: SET_CUSTOMER_NETWORK_NAME, customerNetworkName});

export const setJourneyInitialAddress = journeyInitialAddress => ({type: SET_JOURNEY_INITIAL_ADDRESS, journeyInitialAddress});

export const setJourneyAddress = journeyAddress => ({type: SET_JOURNEY_ADDRESS, journeyAddress});

export const setActiveDecisionTab = activeDecisionTab => ({type: SET_ACTIVE_DECISION_TAB, activeDecisionTab});

export const setActiveDecisionKind = activeDecisionKind => ({type: SET_ACTIVE_DECISION_KIND, activeDecisionKind});

export const setJourneyLocale = journeyLocale => ({type: SET_JOURNEY_LOCALE, journeyLocale});

export const setSteerageTypes = steerageTypes => ({type: SET_STEERAGE_TYPES, steerageTypes});

export const setCustomSteerageTypes = customSteerageTypes => ({type: SET_CUSTOM_STEERAGE_TYPES, customSteerageTypes});

export const setProductHostname = productHostname => ({type: SET_PRODUCT_HOSTNAME, productHostname});

export const setSearchContextId = searchContextId => ({type: SET_SEARCH_CONTEXT_ID, searchContextId});

export const setProviderSearchExtraParams = providerSearchExtraParams => ({type: SET_PROVIDER_SEARCH_EXTRA_PARAMS, providerSearchExtraParams});

export const setKisxData = kisxData => ({type: SET_KISX_DATA, kisxData});

export const requestProcedureById = procedureId => async () => {
    const {data} = await apiServices.getProcedureById(procedureId);
    const {name: procedureName} = data;

    return procedureName;
};

export const requestSpecialtyGroupById = specialtyGroupId => async () => {
    const {data} = await apiServices.getSpecialtyGroupById(specialtyGroupId);
    const {name: specialtyName} = data;

    return specialtyName;
};

export const requestDecisionCenterBasicData = journeyId => async dispatch => {
    const draftTicket = await dispatch(getDraftData(DRAFT_ITEMS.draftTicketList, journeyId));

    const {data, isSuccess} = await apiServices.getCardDecisions({journeyId});

    if (!isSuccess) {
        return false;
    }

    const {
        locale,
        profiles: customerRelatedProfiles,
        extra,
        possible_rewards_programs: possibleRewardsPrograms,
        ticket_link: OTRSTicketLink,
        service_request: serviceRequest,
        steerage_type_id: steerageTypeId,
        custom_steerage_type_id: customSteerageTypeId,
        monetary_value: monetaryValue,
        is_tpa_user: isTpaUser
    } = data;
    const {
        intake,
        type: decisionType,
        decisions,
        decision_providers: decisionProviders = {},
        resolution,
        initial_location: serviceRequestInitialLocation,
        requested_for: customerId,
        ...restServiceRequest
    } = serviceRequest ?? {};
    const {
        procedure_id: procedureId,
        specialty_id: specialtyId,
        questions,
        request_type: requestType
    } = intake ?? {};
    const procedureName = procedureId && await dispatch(requestProcedureById(procedureId));
    const specialtyName = specialtyId && await dispatch(requestSpecialtyGroupById(specialtyId));
    const isQuestionsNeedsUpdate = procedureName && questions;
    const updatedQuestions = isQuestionsNeedsUpdate ? questions.map(question => equal(question, 'procedure-or-service') ? procedureName : question) : questions;
    const updatedRequstType = requestType && equal(requestType, 'procedure-or-service') ? `${procedureName || ''} | ${specialtyName || ''}` : requestType;
    const {npi: intakeNpi, office_ids: officeIds = [], office_id: officeId} = intake ?? {};
    const {source, initial_address: extraInitialAddress, initial_location: extraInitialLocation} = extra ?? {};
    const {title: cardTitle, ...restResolution} = resolution ?? {};
    const updatedResolution = {...restResolution, title: cardTitle ?? DECISION_DEFAULT_CARD_TITLES[decisionType]};
    const formType = DECISION_FORMS_BY_TYPE[decisionType];

    const intakeOfficeIds = [...officeIds, officeId].filter(Boolean);
    const {provider} = intakeNpi ? await dispatch(requestProvider({npi: intakeNpi})) : {provider: {}};
    const {offices: providerOffices = [], highlight: providerHighlight, ...restProvider} = provider;
    const sortedProviderOffices = !isEmpty(intakeOfficeIds) ? [...providerOffices].sort(sortOfficeByIntakeOfficeId(intakeOfficeIds)) : providerOffices;
    const updatedDecisionProviders = {
        ...decisionProviders,
        ...(intakeNpi ? {[intakeNpi]: {...restProvider, highlight: providerHighlight, offices: sortedProviderOffices}} : {})
    };

    const isAppSource = equal(source, TICKET_SOURCES.app);
    const isGenericProviderType = equal(decisionType, DECISION_TYPES.genericProvider);
    const baseDecision = isAppSource && !isGenericProviderType ? [generateBaseDecision({decisionType, npi: intakeNpi, providerHighlight, officeIds, officeId, order: 0})] : [];
    const updatedDecisions = !isEmpty(decisions) ? decisions.map(getUpdatedDecision) : baseDecision;
    const {decisions: draftDecisions} = draftTicket ?? {};

    const [initDecision] = draftDecisions || updatedDecisions;
    const {kind: initDecisionKind, details} = initDecision ?? {};
    const {npi: initProviderNpi} = details ?? {};

    // FYI: It's a fallback for old tickets, because the new tickets always have data into "service_request.initial_location" (Slava, 03.11.2022)
    const {lat, lon, ...restInitialLocation} = serviceRequestInitialLocation || extraInitialLocation || {};
    const journeyInitialAddress = !isEmpty(restInitialLocation) ? formatAddress(restInitialLocation) : extraInitialAddress;

    const basicData = {
        possibleRewardsPrograms,
        intakeInfo: {...intake, questions: updatedQuestions, request_type: updatedRequstType, OTRSTicketLink, locale},
        journeyLocale: locale,
        isTpaUser
    };

    const formData = {
        ...restServiceRequest,
        requested_for: customerId,
        steerage_type_id: steerageTypeId,
        custom_steerage_type_id: customSteerageTypeId,
        monetary_value: monetaryValue,
        intake,
        type: decisionType,
        resolution: updatedResolution,
        decisions: updatedDecisions
    };

    const initialData = draftTicket || formData;

    const customerProfile = customerRelatedProfiles.find(getEqual(customerId, 'id'));

    dispatch(receiveDecisionCenterBasicData(basicData));
    dispatch(setActiveNpi(initProviderNpi));
    dispatch(setProviderList(updatedDecisionProviders));
    dispatch(setJourneyInitialAddress(journeyInitialAddress));
    dispatch(setJourneyAddress(journeyInitialAddress));
    dispatch(setSelectedCustomerProfile(customerProfile));
    dispatch(setActiveDecisionKind(initDecisionKind));
    dispatch(setCustomerRelatedProfiles(customerRelatedProfiles));
    dispatch(setProviderSearchExtraParams({lat, lon, profile_id: customerId}));
    dispatch(setFormData({[formType]: initialData}));

    return {decisionType};
};

export const requestCardDecisionUpdating = (journeyId, cardDecision) => async () => {
    const {data, isSuccess} = await apiServices.updateCardDecision({journeyId, cardDecision});

    return {isSuccess, submissionErrors: data.messages};
};

export const requestCardPreview = (journeyId, journeyLocale, decisionCenterFormData) => async () => {
    const {data, isSuccess} = await apiServices.getCardPreview({journeyId, decisionCenterFormData});

    if (!isSuccess) {
        return false;
    }

    const {preview_url: previewUrl} = data;
    const {url, query} = parseURL(previewUrl);
    const updatedPreviewUrl = stringifyURL({url, query: {...query, language: journeyLocale, previewContext: DECISION_CENTER_CONTEXT_NAME}});

    return {previewUrl: updatedPreviewUrl};
};

export const requestCardTemplates = (journeyId, locale) => async dispatch => {
    const cardTemplatesLocale = locale ?? LANGUAGE_LOCALES.en;
    const {data} = await apiServices.getCardTemplates({journeyId, locale: cardTemplatesLocale});
    const {templates: cardTemplates} = data;
    const updatedCardTemplates = groupBy(cardTemplates, 'category');

    dispatch(receiveCardTemplates(updatedCardTemplates));
};

export const requestCustomerBasicData = customerId => async dispatch => {
    const {data: customer, isSuccess: isCustomerSuccess} = await apiServices.getCustomer({customerId});

    if (!isCustomerSuccess) {
        return false;
    }

    const {plan, product} = customer;
    const {network_name: networkName} = plan ?? {};
    const {hostname: productHostname} = product ?? {};

    dispatch(setCustomerNetworkName(networkName));
    dispatch(setProductHostname(productHostname));
    dispatch(setSearchContextId(customerId));
};

export const requestJourney = journeyId => async () => {
    const {data} = await apiServices.getJourney({journeyId});
    const {user_id: customerId} = data;

    return {customerId};
};

export const requestSteerageTypes = () => async dispatch => {
    const {data} = await apiServices.getSteerageTypes();
    const {steerage_types: steerageTypes = []} = data ?? {};

    dispatch(setSteerageTypes(steerageTypes));
};

export const requestCustomSteerageTypes = customerId => async dispatch => {
    const {data} = await apiServices.getCustomSteerageTypes({customerId});
    const {steerage_types: customSteerageTypes = []} = data ?? {};

    dispatch(setCustomSteerageTypes(customSteerageTypes));
};

export const requestCustomerAccessToken = customerId => async dispatch => {
    const {data} = await apiServices.getCustomerAccessToken({customerId});
    const {access_token: accessToken} = data;

    dispatch(setCustomerAccessToken(accessToken));
};

export const requestCustomerWalletCards = customerId => async dispatch => {
    const {data} = await apiServices.getWalletCards({customerId});
    console.log(data);
    const {cards = []} = data;
    const walletCards = cards.map(getFormattedWalletCards);

    console.log(cards);
    console.log(walletCards);

    dispatch(setWalletCards(walletCards));
};

export const requestCptGroups = query => async () => {
    const {data, isSuccess} = await apiServices.getCptGroups({query});
    const cptGroups = isSuccess ? data : [];

    return {cptGroups};
};

export const requestKisxData = query => async dispatch => {
    const {data, isSuccess} = await apiServices.getKisx({query});
    const kisxData = isSuccess ? data : [];

    dispatch(setKisxData(kisxData));
};
