import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {FieldArray} from '../../shared/FormComponents';
import DecisionDetails from '../DecisionDetails';
import WalletCardDetails from '../WalletCardDetails';
import withPopup from '../../../HOC/withPopup';
import {setActiveDecisionTab, setActiveDecisionKind} from '../../../actions/decisionCenter';
import {deleteDraftData, setFormData} from '../../../actions/shared';
import {setActiveNpi} from '../../../actions/providers';
import {getActiveDecisionTab, getWalletCards} from '../../../selectors/decisionCenter';
import {getProviderList} from '../../../selectors/providers';
import useForm from '../../../hooks/useForm';
import {getWalletCardById} from '../../../helpers';
import {equal, getEqual, negateFunc, compose} from '../../../utils';
import {DECISION_TYPES, DECISION_KINDS, DRAFT_ITEMS, DECISION_FORMS_BY_TYPE} from '../../../constants';
import styles from './index.module.scss';

const POPUP_ID = 'decisionsPopup';
// TODO: Should split DecisionDetails component to ProviderDetails and AppointmentDetails, but event better change name to AppointmentDecision for example (Slava, 14.04.2022)
const DECISION_BY_KINDS = {
    [DECISION_KINDS.provider]: DecisionDetails,
    [DECISION_KINDS.appointment]: DecisionDetails,
    [DECISION_KINDS.walletCard]: WalletCardDetails
};

const Decisions = ({decisionType, isRemovable, areMultiOfficesIncluded, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const formType = DECISION_FORMS_BY_TYPE[decisionType];
    const {values} = form.getState();
    const {decisions} = values;
    const providerList = useSelector(getProviderList);
    const activeDecisionTab = useSelector(getActiveDecisionTab);
    const walletCards = useSelector(getWalletCards);
    console.log(walletCards);
    const walletCardsOptions = useMemo(() => walletCards.map(({title, planId, category}) => ({label: title, value: planId || category})), [walletCards]);
    const isAppointmentAvailable = equal(decisionType, DECISION_TYPES.appointment);
    console.log(walletCardsOptions);

    return (
        <FieldArray name='decisions'>
            {({fields}) => fields.map((field, index) => {
                const isActive = equal(index, activeDecisionTab);
                const {cid, kind, details} = fields.value[index] ?? {};
                const {npi, procedure, category, walletCardId, is_pre_cert_event: isPreCertEvent} = details;
                const currentWalletCard = walletCardId ? walletCards.find(getWalletCardById(walletCardId)) : {};
                const walletCardTitle = currentWalletCard?.title ?? category;
                const {name: providerName, offices = []} = providerList[npi] ?? {};
                const isProcedureExists = !!procedure && Object.values(procedure).some(Boolean);
                const DecisionComponent = DECISION_BY_KINDS[kind];
                const isWalletCardKind = equal(kind, DECISION_KINDS.walletCard);

                const decisionDetailsProps = {
                    prefixName: field,
                    isPreCertEvent,
                    ...isWalletCardKind && {
                        currentWalletCard,
                        walletCards,
                        walletCardsOptions,
                        isWalletCardPreviewShowed: isActive
                    },
                    ...!isWalletCardKind && {
                        offices,
                        isProcedureExists,
                        isAppointmentAvailable,
                        areMultiOfficesIncluded
                    }
                };

                const onOpenRemovingPopup = () => {
                    const onRemove = async () => {
                        const isFirstDecision = equal(index, 0);
                        const {kind: nextDecisionKind, details: {npi: nextProviderNpi}} = fields.value[isFirstDecision ? index + 1 : 0];
                        const filteredDecisions = decisions.filter(negateFunc(getEqual(cid, 'cid')));
                        const updatedFormData = {...values, decisions: filteredDecisions};
                        form.change('decisions', filteredDecisions);

                        dispatch(deleteDraftData(DRAFT_ITEMS.draftProviderList, npi));
                        dispatch(setFormData({[formType]: updatedFormData}));
                        dispatch(setActiveNpi(nextProviderNpi));
                        dispatch(setActiveDecisionTab(0));
                        dispatch(setActiveDecisionKind(nextDecisionKind));
                        closePopup();
                    };

                    const actionBar = (
                        <React.Fragment>
                            <Button type={BUTTON_TYPES.primary} onClick={onRemove}>Yes</Button>
                            <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                        </React.Fragment>
                    );

                    const decisionTitle = providerName || walletCardTitle || `__ Decision ${index + 1}`;
                    const popupContent = <Text isInline>Are you sure you want to remove decision with <Text isInline type={TEXT_TYPES.bodyBold}>{decisionTitle}</Text>?</Text>;
                    const popupProps = {title: 'Remove decision', actionBar, children: popupContent};
                    const children = <PopupContent {...popupProps}/>;

                    return openPopup({modalType: 'simple', children});
                };

                return (
                    <div key={cid} className={classnames(styles.decision, {[styles.isHidden]: !isActive})}>
                        {/* TODO: Right now the "Remove decision" button renders for each decision, but it have to be a single button on the page (Slava, 09.09.2022) */}
                        {isRemovable && fields.value.length > 1 && (
                            <Row className='mb-10'>
                                <Column>
                                    <Button type={BUTTON_TYPES.destructive} onClick={onOpenRemovingPopup}>
                                        Remove decision
                                    </Button>
                                </Column>
                            </Row>
                        )}

                        <ContentSection className='mb-10'>
                            <DecisionComponent {...decisionDetailsProps}/>
                        </ContentSection>
                    </div>
                );
            })}
        </FieldArray>
    );
};

Decisions.propTypes = {
    decisionType: PropTypes.string.isRequired,
    isRemovable: PropTypes.bool,
    areMultiOfficesIncluded: PropTypes.bool,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

Decisions.defaultProps = {
    isRemovable: false,
    areMultiOfficesIncluded: false
};

export {Decisions as TestableDecisions};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(Decisions);
