import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import {Form, Field, FormSpy} from '../../shared/FormComponents';
import ScoreCard from '../../shared/ScoreCard';
import Offices from '../Offices';
import ProviderSpecialties from '../ProviderSpecialties';
import withPopup from '../../../HOC/withPopup';
import usePrevious from '../../../hooks/usePrevious';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import {requestProvider, requestProviderUpdating, setActiveOffice, setProviderList} from '../../../actions/providers';
import {notify, deleteDraftData, getDraftData, saveDraftData} from '../../../actions/shared';
import {getActiveNpi, getProviderList, getActiveOffice} from '../../../selectors/providers';
import {
    validateRequired,
    validateInt,
    validateLength,
    validateURL,
    validate24HoursTimeFormat,
    equal,
    formatDate,
    getUtcToZonedTime,
    compose,
    isEmpty,
    isObject,
    getErrorFieldNames,
    normalizeNumber
} from '../../../utils';
import {
    PROVIDER_TYPES,
    TIME_FORMAT_24_HOURS_REGEXP,
    NOTIFICATION_CLOSING_DELAY,
    DEFAULT_PROVIDER_OFFICE_INDEX,
    DRAFT_ITEMS
} from '../../../constants';
import {
    GENDER_OPTIONS,
    RATING_OPTIONS,
    AVATARS_OPTIONS,
    ORGANIZATION_IMAGES_OPTIONS,
    PROVIDER_CREDENTIAL_OPTIONS
} from '../../../options';

const FORM_SPY_SUBSCRIPTION = {dirty: true, values: true};
const POPUP_ID = 'providerFormPopup';

const validateZIPLength = validateLength(5);

/* istanbul ignore next */
const validateWorkingHours = ({start_hour: startHour, end_hour: endHour}) => {
    return {
        start_hour: startHour || endHour ? validate24HoursTimeFormat(startHour) : undefined,
        end_hour: endHour || startHour ? validate24HoursTimeFormat(endHour) : undefined
    };
};

/* istanbul ignore next */
const validateOffice = values => {
    const {website_url: websiteUrl, location, working_hours: workingDays = []} = values;
    const {zip_code: zipCode, state, city, street_address: streetAddress} = location ?? {};

    return {
        website_url: websiteUrl ? validateURL(websiteUrl) : undefined,
        location: {
            zip_code: validateRequired(zipCode) || validateInt(zipCode) || validateZIPLength(zipCode),
            state: validateRequired(state),
            city: validateRequired(city),
            street_address: validateRequired(streetAddress)
        },
        working_hours: workingDays.map(validateWorkingHours)
    };
};

/* istanbul ignore next */
const validate = values => {
    const {
        display_name: displayName,
        is_display_name_enabled: isDisplayNameEnabled,
        age,
        ein,
        avatar_url: avatarUrl,
        website_url: websiteUrl,
        offices = []
    } = values;

    return {
        display_name: isDisplayNameEnabled ? validateRequired(displayName?.trim()) : undefined,
        age: age ? validateInt(age) : undefined,
        ein: ein ? validateInt(ein) : undefined,
        avatar_url: avatarUrl ? validateURL(avatarUrl) : undefined,
        website_url: websiteUrl ? validateURL(websiteUrl) : undefined,
        offices: offices.map(validateOffice)
    };
};

const filterByExistingHours = ({start_hour: startHour, end_hour: endHour}) => TIME_FORMAT_24_HOURS_REGEXP.test(startHour) && TIME_FORMAT_24_HOURS_REGEXP.test(endHour);

const getFormattedNetwork = ({network_id: networkId}) => networkId;

const getFormattedOffice = ({working_hours: workingDays, networks, hidden_networks: hiddenNetworks, ...restOffice}) => ({
    ...restOffice,
    working_hours: workingDays.filter(filterByExistingHours),
    networks: networks.map(getFormattedNetwork),
    hidden_networks: hiddenNetworks.map(getFormattedNetwork)
});

const ProviderForm = ({className, isProviderDisableFieldsDisplayed, isCustomerAddressDisplayed, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const [provider, setProvider] = useState({});
    const providerList = useSelector(getProviderList);
    const activeNpi = useSelector(getActiveNpi);
    const prevNpi = usePrevious(activeNpi);
    const activeOffice = useSelector(getActiveOffice);
    const [isProviderEditable, setIsProviderEditable] = useState(false);
    let form;

    useEffect(() => {
        if (prevNpi && !equal(prevNpi, activeNpi) && !!form) {
            const {values} = form.getState();
            dispatch(setProviderList({...providerList, [prevNpi]: values}));
        }
    }, [form, providerList, prevNpi, activeNpi, dispatch]);

    useEffect(() => {
        (async () => {
            const provider = providerList[activeNpi];
            const {provider: loadedProvider} = provider?.isFullDataLoaded ? {provider} : await dispatch(requestProvider({npi: activeNpi}));
            const draftProvider = await dispatch(getDraftData(DRAFT_ITEMS.draftProviderList, activeNpi));

            setProvider(draftProvider || loadedProvider);
            setIsProviderEditable(!!draftProvider);
        })();
    }, [activeNpi, providerList, dispatch]);

    let submit;
    const onSubmit = async values => {
        const {npi, offices, ...restValues} = values;
        const formattedOffices = offices.map(getFormattedOffice);
        const updatedValues = {...restValues, npi, offices: formattedOffices};
        const {isSuccess, submissionErrors} = await dispatch(requestProviderUpdating(npi, updatedValues, values));

        if (!isSuccess) {
            const {offices = []} = submissionErrors;
            const officeIndex = offices.findIndex(isObject);

            if (!equal(officeIndex, -1) && !equal(officeIndex, activeOffice)) {
                await dispatch(setActiveOffice(officeIndex));
            }

            return submissionErrors;
        }

        dispatch(deleteDraftData(DRAFT_ITEMS.draftProviderList, npi));
        dispatch(setActiveOffice(DEFAULT_PROVIDER_OFFICE_INDEX));
        dispatch(notify('Provider was saved!', {autoCloseDelay: NOTIFICATION_CLOSING_DELAY}));
        setIsProviderEditable(false);
    };

    const openProviderSavingPopup = () => {
        const onSave = async () => {
            await submit();
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.primary} onClick={onSave}>Yes</Button>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
            </React.Fragment>
        );

        const popupProps = {title: 'Are you sure you want to save your changes?', actionBar, children: null};
        const children = <PopupContent {...popupProps}/>;

        openPopup({modalType: 'simple', children});
    };

    const switchToOfficeWithErrors = errors => {
        const {offices = []} = errors ?? {};
        const foundOfficeTabsWithErrors = offices.reduce((acc, office, index) => !isEmpty(getErrorFieldNames(office)) ? [...acc, index] : acc, []);

        if (isEmpty(foundOfficeTabsWithErrors)) {
            return;
        }

        const [officeTabIndex] = foundOfficeTabsWithErrors;
        dispatch(setActiveOffice(officeTabIndex));
    };

    const saveProvider = async (isValid, errors, form) => {
        if (!isValid) {
            await form.pauseValidation();
            await switchToOfficeWithErrors(errors);
            await form.resumeValidation();
            // FYI: After office tab was switched, we need to highlight fields with errors, therefore have to call "submit" (Slava, 03.06.2022)
            submit();

            return;
        }

        openProviderSavingPopup();
    };

    const onSwitchProviderEditing = () => setIsProviderEditable(!isProviderEditable);

    const onChangeFormSpy = (values, dirty) => {
        if (dirty) {
            dispatch(saveDraftData(DRAFT_ITEMS.draftProviderList, activeNpi, values));
        }
    };

    const onDebouncedFormChange = useDebouncedCallback(({dirty: isDirty, values}) => onChangeFormSpy(values, isDirty));

    return !isEmpty(provider) && (
        <div className={classnames(className)}>
            <Form initialValues={provider} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, values, form: formInstance, valid: isValid, errors}) => {
                    form = formInstance;
                    submit = handleSubmit;
                    const {
                        type,
                        specialties,
                        source,
                        updated,
                        updated_by: updatedBy,
                        earliest_availability_updated: earliestAvailabilityUpdated,
                        earliest_availability_updated_by: earliestAvailabilityUpdatedBy,
                        is_disabled: isProviderDisabled,
                        is_disabled_updated: providerDisabledDate,
                        is_disabled_updated_by: providerDisabledBy,
                        is_display_name_enabled: isDisplayNameEnabled,
                        cost_efficiency_score: costEfficiencyScore,
                        clinical_outcomes_score: clinicalOutcomesScore,
                        rating,
                        name: providerName,
                        npi
                    } = values;
                    const isIndividualProvider = equal(type, PROVIDER_TYPES.individual);
                    const imagesOptions = isIndividualProvider ? AVATARS_OPTIONS : ORGANIZATION_IMAGES_OPTIONS;
                    const officesProps = {source, isCustomerAddressDisplayed, isOfficeEditable: isProviderEditable};

                    const onChangeAvatarUrl = ({target}) => {
                        const {value} = target;

                        form.change('avatar_url', value);
                    };

                    const onSaveProvider = () => saveProvider(isValid, errors, form);

                    return (
                        <form noValidate>
                            <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onDebouncedFormChange}/>
                            <ContentSection className='mb-12'>
                                {isProviderDisabled && (
                                    <Row className='mb-12'>
                                        <Column sm={12}>
                                            <Alert type={ALERT_TYPES.danger} description='Provider was banned!'/>
                                        </Column>
                                    </Row>
                                )}

                                <Row middle='sm' start='sm' className='mb-6'>
                                    <Column className='mb-12'>
                                        <Heading type={HEADING_TYPES['4']}>Provider details</Heading>
                                    </Column>
                                    <Column sm={2} className='mb-12'>
                                        {!isProviderEditable && (
                                            <Button type={BUTTON_TYPES.tertiary} onClick={onSwitchProviderEditing}>Edit Info</Button>
                                        )}

                                        {isProviderEditable && (
                                            <Button type={BUTTON_TYPES.tertiary} onClick={onSwitchProviderEditing}>Cancel</Button>
                                        )}
                                    </Column>
                                </Row>

                                {updated && updatedBy && (
                                    <Text data-testid='updated-information' className='mb-5' isSmall>
                                        Last updated on
                                        &nbsp;
                                        <Text isInline type={TEXT_TYPES.bodyBold}>{formatDate(getUtcToZonedTime(updated), 'MM/dd/yyyy h:mm aa z')}</Text>
                                        &nbsp;
                                        by <Text isInline type={TEXT_TYPES.bodyBold}>{updatedBy}</Text>
                                    </Text>
                                )}

                                {isProviderDisableFieldsDisplayed && (
                                    <React.Fragment>
                                        <Row data-testid='disabled-fields'>
                                            <Column xs={12} className='mb-10'>
                                                <Field name='is_disabled'>
                                                    {props => <Checkbox {...props} disabled={!isProviderEditable} caption='Is banned'/>}
                                                </Field>
                                            </Column>
                                            <Column xs={12}>
                                                <Field name='is_disabled_updated_reason'>
                                                    {props => <Textarea {...props} label='Reason' disabled={!isProviderEditable} maxLength={1024} rows={4}/>}
                                                </Field>
                                            </Column>
                                            {isProviderDisabled && providerDisabledDate && providerDisabledBy && (
                                                <Column xs={12}>
                                                    <Row end='xs'>
                                                        <Column>
                                                            <Text data-testid='disabled-information' isSmall>
                                                                Was banned on
                                                                &nbsp;
                                                                <Text isInline type={TEXT_TYPES.bodyBold}>{formatDate(getUtcToZonedTime(providerDisabledDate), 'MM/dd/yyyy h:mm aa z')}</Text>
                                                                &nbsp;
                                                                by <Text isInline type={TEXT_TYPES.bodyBold}>{providerDisabledBy}</Text>
                                                            </Text>
                                                        </Column>
                                                    </Row>
                                                </Column>
                                            )}
                                        </Row>

                                        <Separator/>
                                    </React.Fragment>
                                )}

                                <Row className='mt-12 mb-12'>
                                    <Column sm={12} className='mb-12'>
                                        <Heading className='mb-2' type={HEADING_TYPES['4']}>{providerName}</Heading>
                                        <Text type={TEXT_TYPES.bodyBold}>NPI: {npi}</Text>
                                    </Column>
                                    <Column sm={3}>
                                        <ScoreCard value={costEfficiencyScore} title='Cost Efficiency' icon='moneyCouple'/>
                                    </Column>
                                    <Column sm={3}>
                                        <ScoreCard value={clinicalOutcomesScore} title='Outcomes Quality' icon='care'/>
                                    </Column>
                                    <Column sm={3}>
                                        <ScoreCard value={rating} title='Patient Ratings' icon='starFull'/>
                                    </Column>
                                </Row>

                                <Separator/>

                                <Row className='mt-12'>
                                    <Column sm={6}>
                                        <Field name='name'>{props => <Input {...props} label='Name' disabled/>}</Field>
                                    </Column>

                                    <Column sm={6} className='mb-5'>
                                        <Field name='display_name'>
                                            {props => <Input {...props} label='Display name' data-testid='display_name' disabled={!isDisplayNameEnabled || !isProviderEditable}/>}
                                        </Field>

                                        <Field name='is_display_name_enabled' className='mb-12 mt-6'>
                                            {props => <Checkbox {...props} disabled={!isProviderEditable} caption='Use display name'/>}
                                        </Field>
                                    </Column>

                                    {isIndividualProvider && (
                                        <React.Fragment>
                                            <Column sm={6} className='mb-12'>
                                                <Field name='credential'>
                                                    {props => <Select {...props} label='Title' data-testid='credential' disabled={!isProviderEditable} options={PROVIDER_CREDENTIAL_OPTIONS}/>}
                                                </Field>
                                            </Column>

                                            <Column sm={3}>
                                                <Field name='gender'>
                                                    {props => <Select {...props} label='Gender' data-testid='gender' disabled={!isProviderEditable} options={GENDER_OPTIONS}/>}
                                                </Field>
                                            </Column>
                                            <Column sm={3}>
                                                <Field name='age' parse={normalizeNumber}>
                                                    {props => <Input {...props} label='Age' data-testid='age' disabled={!isProviderEditable} type='number' placeholder='...'/>}
                                                </Field>
                                            </Column>
                                        </React.Fragment>
                                    )}

                                    <Column sm={2}>
                                        <Field name='internal_rating'>
                                            {props => <Select {...props} label='MS Rating' disabled={!isProviderEditable} options={RATING_OPTIONS} placeholder='Select...'/>}
                                        </Field>
                                    </Column>

                                    {isIndividualProvider && (
                                        <React.Fragment>
                                            <Column sm={5}>
                                                <Field name='traits.residency_training'>
                                                    {props => <Input {...props} label='Residency' data-testid='residency_training' disabled={!isProviderEditable} maxLength={50}/>}
                                                </Field>
                                            </Column>

                                            <Column sm={5}>
                                                <Field name='traits.medical_school'>
                                                    {props => <Input {...props} label='Education' data-testid='medical_school' disabled={!isProviderEditable} maxLength={50}/>}
                                                </Field>
                                            </Column>

                                            <Column xs={12} className='mb-10'>
                                                <Field name='traits.is_board_certified'>
                                                    {props => <Checkbox {...props} data-testid='is_board_certified' disabled={!isProviderEditable} caption='Board certified'/>}
                                                </Field>
                                            </Column>
                                        </React.Fragment>
                                    )}

                                    <Column sm={8} className='mb-12'>
                                        <Field name='website_url'>
                                            {props => <Input {...props} label='Website' disabled={!isProviderEditable} maxLength={255}/>}
                                        </Field>
                                    </Column>

                                    <Column xs={12} className='mb-12'>
                                        <ProviderSpecialties isEditable={isProviderEditable} specialties={specialties}/>
                                    </Column>
                                </Row>

                                <Row>
                                    <Column sm={8} className='mb-12'>
                                        <Field name='avatar_url'>
                                            {props => <Input {...props} label='Photo URL' disabled={!isProviderEditable} maxLength={1000}/>}
                                        </Field>
                                    </Column>
                                </Row>

                                <Row>
                                    <Column sm={3}>
                                        <RadioGroup data-testid='avatar_url'
                                            label='Default images'
                                            value={values.avatar_url}
                                            options={imagesOptions}
                                            disabled={!isProviderEditable}
                                            onChange={onChangeAvatarUrl}/>
                                    </Column>
                                    <Column sm={4}>
                                        <img src={values.avatar_url} alt=''/>
                                    </Column>
                                </Row>

                                <Separator type='solid'/>

                                <Heading className='mb-12' type={HEADING_TYPES['4']}>
                                    Internal information
                                </Heading>

                                <Row>
                                    <Column sm={3} className='mb-12'>
                                        <Field name='npi'>{props => <Input {...props} label='NPI' disabled/>}</Field>
                                    </Column>
                                    <Column sm={3} className='mb-12'>
                                        <Field name='ein'>
                                            {props => <Input {...props} label='EIN' disabled={!isProviderEditable} placeholder='Enter EIN'/>}
                                        </Field>
                                    </Column>
                                    <Column sm={6} className='mb-12'>
                                        <Row>
                                            <Column sm={6}>
                                                <Field name='earliest_availability'>
                                                    {props => <DatePicker {...props} label='Earliest Availability' disabled={!isProviderEditable}/>}
                                                </Field>
                                            </Column>
                                        </Row>

                                        {earliestAvailabilityUpdated && (
                                            <Text data-testid='earliest-availability-information' isSmall>
                                                Last updated on
                                                &nbsp;
                                                <Text isInline type={TEXT_TYPES.bodyBold}>{formatDate(getUtcToZonedTime(earliestAvailabilityUpdated), 'MM/dd/yyyy h:mm a z')}</Text>
                                                &nbsp;
                                                by <Text isInline type={TEXT_TYPES.bodyBold}>{earliestAvailabilityUpdatedBy}</Text>
                                            </Text>
                                        )}
                                    </Column>

                                    <Column xs={6} className='mb-12'>
                                        <Field name='is_thirdparty_scheduling_supported'>
                                            {props => (
                                                <Checkbox {...props}
                                                    disabled={!isProviderEditable}
                                                    caption='Supports 3-party scheduling'/>
                                            )}
                                        </Field>
                                    </Column>
                                </Row>
                            </ContentSection>

                            <Offices {...officesProps}/>

                            {isProviderEditable && (
                                <Row end='sm'>
                                    <Column xs={4} className='pt-15'>
                                        <Button type={BUTTON_TYPES.secondary} onClick={onSaveProvider}>Save provider changes</Button>
                                    </Column>
                                </Row>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

ProviderForm.propTypes = {
    className: PropTypes.string,
    isProviderDisableFieldsDisplayed: PropTypes.bool,
    isCustomerAddressDisplayed: PropTypes.bool,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

ProviderForm.defaultProps = {
    className: '',
    isCustomerAddressDisplayed: false,
    isProviderDisableFieldsDisplayed: false
};

export {ProviderForm as TestableProviderForm};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(ProviderForm);
