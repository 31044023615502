import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Table from '@frontend/ui-kit/Components/Table';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Link from '@frontend/ui-kit/Components/Link';
import SearchContextExpanded from '../SearchContextExpanded';
import {requestSearchContextList} from '../../../actions/providers';
import {getSearchContextId, getIntakeInfo} from '../../../selectors/decisionCenter';
import {GOOGLE_MAPS_BASE_URL} from '../../../constants';
import {getValueCell} from '../../../helpers';
import {formatDate, getUtcToZonedTime, equal, toCapitalize} from '../../../utils';
import styles from './index.module.scss';

const PAGE_SIZE = 10;
const LOADING_LIMIT = 50;

const getTotalPages = data => Math.ceil(data.length / PAGE_SIZE);

const SearchContexts = props => {
    const {className} = props;
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [isExtraLoadingAvailable, setIsExtraLoadingAvailable] = useState(false);
    const [searchContexts, setSearchContexts] = useState([]);
    const searchContextId = useSelector(getSearchContextId);
    const {search_id: intakeSearchId} = useSelector(getIntakeInfo) ?? {};

    const onSearchContextsLoad = async () => {
        const offset = searchContexts.length;
        const {searchContexts: loadedSearchContexts} = await dispatch(requestSearchContextList({searchContextId, offset}));
        const isExtraLoadingAvailable = equal(loadedSearchContexts.length, LOADING_LIMIT);
        const updatedSearchContexts = [...searchContexts, ...loadedSearchContexts];
        const totalPages = getTotalPages(updatedSearchContexts);

        setSearchContexts(updatedSearchContexts);
        setIsLoaded(true);
        setTotalPages(totalPages);
        setIsExtraLoadingAvailable(isExtraLoadingAvailable);
    };

    const onPageChange = page => {
        const currentPage = page + 1;
        const isLastPage = equal(currentPage, totalPages);

        setIsLastPage(isLastPage);
    };

    const tableColumns = [
        {
            Header: () => null,
            id: 'expander',
            width: 40,
            Cell: cell => {
                const {row} = cell;

                return (
                    <div {...row.getToggleRowExpandedProps()}>
                        <Icon type={row.isExpanded ? ICON_TYPES.chevronUp : ICON_TYPES.chevronDown}/>
                    </div>
                );
            }
        },
        {
            Header: 'Search ID',
            accessor: 'search_id',
            Cell: cell => {
                const {value, row: {original}} = cell;
                const {thirdparty_source: thirdpartySource} = original;

                return <Text className='white-space-normal'>{value} ({toCapitalize(thirdpartySource)})</Text>;
            }
        },
        {
            Header: 'Location',
            accessor: 'metadata',
            Cell: cell => {
                const {value} = cell;
                const {lat, lon, radius} = value;
                const updatedMileText = equal(radius, 1) ? 'mile' : 'miles';
                const mapUrl = `${GOOGLE_MAPS_BASE_URL}/search/?api=1&query=${lat},${lon}`;

                return <Link href={mapUrl} target='_blank'>Map ({radius} {updatedMileText})</Link>;
            }
        },
        {Header: 'Query', accessor: 'metadata.query', Cell: getValueCell},
        {
            Header: 'Search Entity',
            accessor: 'search_entity',
            Cell: cell => {
                const {value} = cell;
                const searchEntityText = value ? `${value.name} (${value.type})` : '-';

                return <Text className='white-space-normal'>{searchEntityText}</Text>;
            }
        },
        {
            Header: 'Network name',
            accessor: 'metadata.network_names',
            Cell: cell => {
                const {value} = cell;

                return <Text className='white-space-normal'>{value ? value.join(', ') : '-'}</Text>;
            }
        },
        {Header: 'Date', accessor: 'updated', Cell: item => <Text className='white-space-normal'>{item.value ? formatDate(getUtcToZonedTime(item.value), 'MM/dd/yyyy h:mm aa') : '-'}</Text>}
    ];

    const tableProps = {
        className: styles['search-contexts-table'],
        data: searchContexts,
        columns: tableColumns,
        isInitialExpansion: false,
        isCellTooltip: false,
        isFilterable: false,
        isSortable: false,
        pageSize: 10,
        onPageChange,
        getSubRow: subRow => {
            const {row: {original}} = subRow;
            const {search_id: searchId} = original;

            return (
                <div className={styles['search-contexts-expanded']}>
                    {/* TODO: Find way to save opened "SearchContext", because after close element will deleted from DOM and next time
                        when open we make extra request (Slava, 29.07.2021)
                    */}
                    <SearchContextExpanded searchId={searchId}/>
                </div>
            );
        },
        getCellProps: cellProps => {
            const {row: {original}} = cellProps;
            const {search_id: searchId} = original;
            const areSearchIdsEqual = equal(searchId, intakeSearchId);

            return {
                style: {
                    background: areSearchIdsEqual ? '#3d70f4' : '#fff',
                    color: areSearchIdsEqual ? '#ffffff' : '#000000'
                }
            };
        }
    };

    return (
        <div className={classnames('search-contexts', className)}>
            {isLoaded && (
                <React.Fragment>
                    <Table {...tableProps}/>

                    {isExtraLoadingAvailable && isLastPage && (
                        <Row center='sm'>
                            <Column sm={4}>
                                <Button type={BUTTON_TYPES.secondary} onClick={onSearchContextsLoad}>Load more results</Button>
                            </Column>
                        </Row>
                    )}
                </React.Fragment>
            )}

            {!isLoaded && (
                <Row center='xs'>
                    <Column sm={4}>
                        <Button type={BUTTON_TYPES.secondary} onClick={onSearchContextsLoad}>Load search contexts</Button>
                    </Column>
                </Row>
            )}
        </div>
    );
};

SearchContexts.propTypes = {
    className: PropTypes.string
};

SearchContexts.defaultProps = {
    className: ''
};

export {SearchContexts as TestableSearchContexts};
export default React.memo(SearchContexts);
