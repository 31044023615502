import Link from '@frontend/ui-kit/Components/Link';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {generateUniqueId, equal} from './utils';
import {
    ROUTES,
    DECISION_CARD_KIND_BY_TYPE,
    DECISION_TYPES,
    DECISION_OFFICE_FIELD_NAMES
} from './constants';

// FYI: Base decision for Provider and Appointment decision kinds (Slava, 14.04.2022)
export const generateBaseDecision = ({decisionType, providerType, npi, providerHighlight, officeId = '', officeIds = [], order}) => ({
    cid: generateUniqueId(),
    order,
    kind: DECISION_CARD_KIND_BY_TYPE[decisionType],
    is_recommended: false,
    details: {
        npi,
        type: providerType,
        [DECISION_OFFICE_FIELD_NAMES[decisionType]]: equal(decisionType, DECISION_TYPES.appointment) ? officeId : officeIds,
        is_availability_not_guaranteed: true,
        is_in_network: null,
        provider_highlight: providerHighlight
    }
});

export const formatAddress = value => {
    const {city, state, street_address: streetAddress, zip_code: zipCode} = value ?? {};
    const formattedStreetAddress = streetAddress ? `${streetAddress}, ` : '';
    const formattedCity = city ? `${city}, ` : '';
    const formattedState = state ? `${state} ` : '';
    const formattedZipCode = zipCode ?? '';

    return `${formattedStreetAddress}${formattedCity}${formattedState}${formattedZipCode}`.trim();
};

export const parseAddress = value => {
    const fullAddress = value ?? '';
    const [streetAddress = '', city = '', zipAndState = ''] = fullAddress.split(', ');
    const [state = '', zipCode = ''] = zipAndState.split(' ');

    return {zip_code: zipCode, state, street_address: streetAddress, city};
};

export const getSpecialtyName = ({display_name: displayName, sub_specialty: subSpecialty, name}) => displayName || subSpecialty || name || '';

export const getOfficeName = ({name, location}, index) => {
    const {street_address: streetAddress} = location ?? {};
    const officeNameParts = [name, streetAddress].filter(Boolean).join(', ');

    return officeNameParts || `__ Office ${index + 1}`;
};

export const getFilledWorkingDay = (workingDays = {}) => (dayName, index) => (
    {...(workingDays[index] ?? {day_of_week: index, start_hour: '', end_hour: '', notes: ''})}
);

/* eslint-disable-next-line */
export const getValueCell = item => <Sticker type={STICKER_TYPES.default}>{item.value ?? '-'}</Sticker>;

/* eslint-disable-next-line */
export const getProviderLinkCell = item => <Link href={`${window.location.origin}${ROUTES.providerRecords}/${item.value}`} target='_blank'>{item.value}</Link>;

export const getWalletCardById = walletCardId => ({category, planId}) => equal(planId || category, walletCardId);
